export function snakeCase(str) {
  if (typeof str !== 'string') {
    return ''; // Or throw an error, depending on desired behavior
  }

  return str
    .replace(/([\s\-_\.]+)/g, ' ') // Replace separators with spaces
    .replace(/([A-Z])/g, ' $1') // Add space before uppercase letters
    .trim() // Remove leading/trailing spaces
    .toLowerCase() // Convert to lowercase
    .replace(/[\s\-_\.]+/g, '_'); // Replace spaces with underscores
}
